import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update,
} from '../services/SubscriptionItemsService.ts';


import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase,
} from '../../../store/RecordsSliceCaseHelpers';
import {SubscriptionItem} from '../types';

const entityKey = 'subscriptionItems';

interface SubscriptionItemsState {
    byId: { [key: string]: SubscriptionItem },
    ids: string[],
    totalCount: number,
    loading: boolean,
    error: string
}

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
} as SubscriptionItemsState;


const normalizeRecord = (record: SubscriptionItem) => {
    return Object.assign({}, record);
};

// generates pending, fulfilled and rejected
export const fetchSubscriptionItems = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    (customerId: string) => {

        return fetchRecords(customerId)
            .then((response) => {
                if (response) {
                    return response.map((record: SubscriptionItem) => {
                        return normalizeRecord(record);
                    });
                }
            });
    },
);

export const fetchSubscriptionItem = createAsyncThunk(
    `${entityKey}/fetch`,
    ({
         customerId,
         id
     }: {
        customerId: string,
        id: string
    }) => {
        return fetch(customerId, id)
            .then((response) => {
                if (typeof response !== 'undefined') {
                    return normalizeRecord(response);
                }
            });
    });

export const saveSubscriptionItem = createAsyncThunk(
    `${entityKey}/add`,
    ({
         customerId,
         payload
     }: { customerId: string, payload: SubscriptionItem }) => {

        const modified = Object.assign({}, payload, {customer: customerId})

        return add(modified)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const updateSubscriptionItem = createAsyncThunk(
    `${entityKey}/update`,
    ({
         customerId,
         id,
         record,
     }: { customerId: string, id: string, record: SubscriptionItem }) => {

        const modified = Object.assign({}, record, {
            id: id,
            customer: customerId
        })

        return update(modified)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    },
);

export const deleteSubscriptionItem = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    ({
         customerId,
         id
     }: {
        customerId: string,
        id: string
    }) => {
        return deleteRecord(customerId, id)
            .then(() => id);
    },
);


export const storeSubscriptionItem = createAsyncThunk(
    `${entityKey}/store`,
    (payload: SubscriptionItem) => {
        return normalizeRecord(payload);
    },
);


const componentsSlice = createSlice({
                                        name: entityKey,
                                        initialState,
                                        reducers: {
                                            clearState: () => initialState,
                                        },
                                        extraReducers: (builder) => {
                                            // FETCH MANY
                                            fetchManyCase(builder, fetchSubscriptionItems, entityKey);

                                            // FETCH ONE
                                            fetchOneCase(builder, fetchSubscriptionItem);

                                            // SAVE ONE
                                            saveOneCase(builder, saveSubscriptionItem);

                                            // UPDATE ONE
                                            updateOneCase(builder, updateSubscriptionItem);

                                            updateOneCase(builder, storeSubscriptionItem);

                                            // DELETE ONE
                                            deleteOneCase(builder, deleteSubscriptionItem);
                                        },
                                    });

export default componentsSlice.reducer;
