import {
    configureStore,
    Store
} from '@reduxjs/toolkit';


import {BankAccountsReducer} from '../features/bank-accounts/reducers/index'
import {CardsReducer} from '../features/cards/reducers/index'
import {InvoicesReducer} from '../features/invoices/reducers/index'
import {InvoiceItemsReducer} from '../features/invoice-items/reducers/index'
import {PricesReducer} from '../features/prices/reducers/index'
import {ProductsReducer} from '../features/products/reducers/index'
import {SubscriptionsReducer} from '../features/subscriptions/reducers/index'
import {SubscriptionItemsReducer} from '../features/subscription-items/reducers/index'
import {CustomersReducer} from '../features/customers/reducers/index'
import {CustomerInvoiceItemsReducer} from '../features/customer-invoice-items/reducers/index'


const reducers = {
    cards: CardsReducer,
    customers: CustomersReducer,
    customerInvoiceItems: CustomerInvoiceItemsReducer,
    bankAccounts: BankAccountsReducer,
    invoices: InvoicesReducer,
    invoiceItems: InvoiceItemsReducer,
    prices: PricesReducer,
    products: ProductsReducer,
    subscriptions: SubscriptionsReducer,
    subscriptionItems: SubscriptionItemsReducer,
};

// added this middleware  to disable this error - A non-serializable value was detected in the state, in the path...
// @todo disable if better way is found
export const store: Store = configureStore({
                                               reducer: reducers,
                                               middleware: (getDefaultMiddleware) =>
                                                   getDefaultMiddleware({
                                                                            serializableCheck: false,
                                                                        }),
                                           });


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch