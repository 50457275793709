import {
    Grid,
    Stack,
} from '@mantine/core';
import {CustomersWidget} from './dashboard/CustomersWidget.tsx';
import {SubscriptionsWidget} from "./dashboard/SubscriptionsWidget.tsx";
import {PricesWidget} from "./dashboard/PricesWidget.tsx";
import {InvoicesWidget} from "./dashboard/InvoicesWidget.tsx";
import {ProductsWidget} from "./dashboard/ProductsWidget.tsx";

export const PortalDashboard = () => {

    return (
        <Grid mx={'10%'}>
            <Grid.Col span={{
                xl: 6,
                lg: 6,
                md: 12,
                sm: 12,
                xs: 12,
            }}>
                <Stack>
                    <CustomersWidget/>
                    <InvoicesWidget/>
                    <ProductsWidget/>
                </Stack>
            </Grid.Col>

            <Grid.Col span={{
                xl: 6,
                lg: 6,
                md: 12,
                sm: 12,
                xs: 12,
            }}>
                <Stack>
                    <SubscriptionsWidget/>
                    <PricesWidget/>
                </Stack>
            </Grid.Col>
        </Grid>
    );
};
