import PropTypes from "prop-types";

import {Grid} from "@mantine/core";

import {ListRowsProps} from "./types";

import {RemoveRowButton} from "./RemoveRowButton";

import {ListRow} from "./ListRow";
import {Field} from "formik";

export const ListRows = ({
                             field,
                             form,
                             remove,
                             getRowElements
                         }: ListRowsProps) => {

    if (Array.isArray(form.values[field.name]) && form.values[field.name].length > 0) {

        return form.values[field.name].map((row: object, rowIndex: number) => {

            const rowElements = getRowElements(row);

            return (
                <Grid key={rowIndex}>
                    <Grid.Col span={11}>
                        <Field name={field.name} rowIndex={rowIndex} rowElements={rowElements} component={ListRow}/>
                    </Grid.Col>
                    <Grid.Col span={1}>
                        <RemoveRowButton remove={remove} rowIndex={rowIndex}/>
                    </Grid.Col>
                </Grid>
            );
        });
    } else {
        return null;
    }
};


ListRows.propTypes = {
    name: PropTypes.string,
    values: PropTypes.object,
    remove: PropTypes.func,
    getRowElements: PropTypes.func
}


