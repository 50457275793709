import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {Invoice} from '../types';

export const columns = [
    {
        title: I18n.get('Name'),
        width: '1fr',
        render: (item: Invoice) => <NavLink to={`/invoices/${item.id}`}>{item.id}</NavLink>,
    },
    {
        title: I18n.get('Customer'),
        width: '1fr',
        render: (item: Invoice) => item.customer,
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: Invoice) => formatTimestamp(item.created as string),
    },
];
