import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {BankAccount} from '../types';

export const columns = [
    {
        title: I18n.get('Bank Account'),
        width: '1fr',
        render: (item: BankAccount) => <NavLink
            to={`/customers/${item.customer}/bank-accounts/${item.id}`}>{item.id}</NavLink>,
    },
    {
        title: I18n.get('Account Holder'),
        width: '1fr',
        render: (item: BankAccount) => item.account_holder_name,
    },
    {
        title: I18n.get('Bank'),
        width: '1fr',
        render: (item: BankAccount) => item.bank_name,
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: BankAccount) => formatTimestamp(item.created as string),
    },
];
