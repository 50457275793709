import {
    deleteSubscription,
    fetchSubscription,
    saveSubscription,
    updateSubscription,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {Subscription} from '../types';

export const useSubscription = (subscriptionId?: string) => {


    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, subscriptionId ? subscriptionId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof subscriptionId === 'undefined') {
            throw new Error('subscriptionId is required parameter');
        }
        dispatch(fetchSubscription(subscriptionId));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: Subscription) => {
        dispatch(saveSubscription(values));
    };

    const updateRecord = (values: Subscription) => {
        if (typeof subscriptionId === 'undefined') {
            throw new Error('subscriptionId is required parameter');
        }

        dispatch(updateSubscription({
                                        id: subscriptionId,
                                        record: values,
                                    }));
    };

    const deleteRecord = () => {
        if (typeof subscriptionId === 'undefined') {
            throw new Error('subscriptionId is required parameter');
        }
        dispatch(deleteSubscription(subscriptionId));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
