import {NavLink as ReactRouteNavLink} from 'react-router-dom';
import {useEffect} from "react";
import {useCustomers} from "../../../customers";
import {
    DashboardWidgetBuilder,
    WidgetDataPoint,
    WidgetType
} from "@flexinet/ui-components";

export const CustomersWidget = () => {

    const {
        items,
        fetchRecords
    } = useCustomers()

    useEffect(() => {
        fetchRecords()
    }, []);

    const dataPoints: WidgetDataPoint[] = [{
        value: items.length,
        label: 'Customers',
        color: 'flexinet',
    }];

    const widget: WidgetType = {
        header: {
            title: 'Customers',
        },
        body: {
            dataPoints: dataPoints,
            metrics: [],
        },
        footer: {
            actionItems: [
                {
                    label: 'View All',
                    component: {ReactRouteNavLink},
                    to: '/customers',
                    visible: true
                },
                {
                    label: 'Onboard Customer',
                    component: {ReactRouteNavLink},
                    to: '/customers/add',
                    visible: true,
                },
            ],
        }
    };

    return <DashboardWidgetBuilder widget={widget}/>;
};
