export const listInvoiceItems = `
   query listInvoiceItems($filter: InvoiceItemsFilterInput ) {
       listInvoiceItems(filter: $filter) {
           items {
                id
                customer
                amount
                currency
                description
                period {
                    start
                    end
                }
                invoice
                quantity
                subscription
                unit_amount
           }
       }
   }
`;

export const getInvoiceItem = `
   query getInvoiceItem($customer: ID!, $id:ID!) {
       getInvoiceItem(customer:$customer, id:$id) {
            id
            customer
            amount
            currency
            description
            period {
                start
                end
            }
            price
            invoice
            quantity
            subscription
            unit_amount
       } 
}`;
