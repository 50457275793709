import PropTypes from "prop-types";

import {
    Stack,
    Text,
    Title
} from "@mantine/core";

import {
    Field,
    FieldArray
} from "formik";

import {
    ListRowElement,
    PacFormikListProps
} from "./types";

import {AddRowButton} from "./AddRowButton";
import {ListRows} from "./ListRows";


export const FormikList = ({
                               field,
                               getRowElements,
                               label,
                               description,
                           }: PacFormikListProps) => {

    return (
        <Stack>
            <Title order={5}>{label}</Title>
            <Text fz="xs">{description}</Text>
            <FieldArray name={field.name}>
                {
                    ({
                         remove,
                         push
                     }) => {

                        // build empty row object
                        const reducer = (result: { [key: string]: any }, element: { name: string }) => {
                            result[element.name] = "";
                            return result;
                        };

                        const rowElements: ListRowElement[] = getRowElements();
                        const emptyRow = rowElements.reduce(reducer, {});

                        return (
                            <Stack>
                                <Field
                                    name={field.name}
                                    // values={form.values}
                                    remove={remove}
                                    getRowElements={getRowElements}
                                    component={ListRows}
                                />

                                <AddRowButton
                                    push={push}
                                    row={emptyRow}
                                />
                            </Stack>
                        )
                    }
                }
            </FieldArray>
        </Stack>
    );
};


FormikList.propTypes = {
    field: PropTypes.object,
    form: PropTypes.object,
    getRowElements: PropTypes.func,
    label: PropTypes.string,
    description: PropTypes.string
};
