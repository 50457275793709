export const createCustomer = `
    mutation createCustomer($input: CreateCustomerInput!) {
        createCustomer(input:$input) {
            id
            name
            description
            email
            phone
    }
}`;

export const updateCustomer = `
    mutation updateCustomer($input: UpdateCustomerInput!) {
        updateCustomer(input:$input){
            id
            name
            description
            email
            phone
        }
}`;

export const deleteCustomer = `
    mutation deleteCustomer($input: DeleteCustomerInput!) {
        deleteCustomer(input:$input) 
}`;
