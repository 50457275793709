import {
    deleteProduct,
    fetchProduct,
    saveProduct,
    updateProduct,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {Product} from '../types';

export const useProduct = (productId?: string) => {


    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, productId ? productId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof productId === 'undefined') {
            throw new Error('productId is required parameter');
        }
        dispatch(fetchProduct(productId));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: Product) => {
        dispatch(saveProduct(values));
    };

    const updateRecord = (values: Product) => {
        if (typeof productId === 'undefined') {
            throw new Error('productId is required parameter');
        }

        dispatch(updateProduct({
                                   id: productId,
                                   record: values,
                               }));
    };

    const deleteRecord = () => {
        if (typeof productId === 'undefined') {
            throw new Error('productId is required parameter');
        }
        dispatch(deleteProduct(productId));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
