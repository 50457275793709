import {
    getSubscriptionItem,
    listSubscriptionItems
} from './queries';
import {
    createSubscriptionItem,
    deleteSubscriptionItem,
    updateSubscriptionItem,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {SubscriptionItem} from "../types.ts";
import {V6Client} from "@aws-amplify/api-graphql";
import {generateClient} from "aws-amplify/api";
import {
    notifyError,
    notifySuccess,
    showErrorNotification
} from "../../../components/ServiceNotifications.tsx";


export type ListSubscriptionItems = {
    listSubscriptionItems: { items: SubscriptionItem[] }
}

export type GetSubscriptionItem = {
    getSubscriptionItem: SubscriptionItem
}

export type CreateSubscriptionItem = {
    createSubscriptionItem: SubscriptionItem
}

export type UpdateSubscriptionItem = {
    updateSubscriptionItem: SubscriptionItem
}

export type DeleteSubscriptionItem = {
    deleteSubscriptionItem: string
}


let client: V6Client
try {
    client = generateClient();
} catch (e: any) {
    showErrorNotification(e)
}

export const fetchRecords = async (customerId: string) => {

    try {
        const response = await client.graphql<GraphQLQuery<ListSubscriptionItems>>({
                                                                                       query: listSubscriptionItems,
                                                                                       variables: {customer: customerId},
                                                                                   });
        return response.data?.listSubscriptionItems.items;
    } catch (e) {
        notifyError(e)
    }
};

export const fetch = async (customerId: string, id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetSubscriptionItem>>({
                                                                                     query: getSubscriptionItem,
                                                                                     variables: {
                                                                                         customer: customerId,
                                                                                         id: id
                                                                                     },
                                                                                 });
        return response.data?.getSubscriptionItem;
    } catch (e) {
        notifyError(e)
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreateSubscriptionItem>>({
                                                                                        query: createSubscriptionItem,
                                                                                        variables: {input: payload},
                                                                                    });
        notifySuccess('Subscription Item Created')
        console.debug('in the service response', response);

        return response.data?.createSubscriptionItem;
    } catch (e) {
        notifyError(e)
    }
};

export const update = async (record: SubscriptionItem) => {

    delete record.created;
    delete record.updated;

    try {
        const response = await client.graphql<GraphQLQuery<UpdateSubscriptionItem>>({
                                                                                        query: (updateSubscriptionItem),
                                                                                        variables: {input: record},
                                                                                    });
        notifySuccess('Subscription Item Updated')
        return response.data?.updateSubscriptionItem;
    } catch (e) {
        notifyError(e)
    }
};

export const deleteRecord = async (customerId: string, id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteSubscriptionItem>>({
                                                                                        query: (deleteSubscriptionItem),
                                                                                        variables: {
                                                                                            input: {
                                                                                                customer: customerId,
                                                                                                id: id
                                                                                            }
                                                                                        },

                                                                                    });
        notifySuccess('Subscription Item Deleted')
        return response.data?.deleteSubscriptionItem;
    } catch (e) {
        notifyError(e)
    }
};
