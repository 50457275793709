import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {CustomerInvoiceItem} from '../types';

export const columns = [
    {
        title: I18n.get('Customer Invoice Item'),
        width: '1fr',
        render: (item: CustomerInvoiceItem) => <NavLink to={`/customer-invoice-items/${item.id}`}>{item.id}</NavLink>,
    },
    {
        title: I18n.get('Quantity'),
        width: '1fr',
        render: (item: CustomerInvoiceItem) => item.quantity,
    },
    {
        title: I18n.get('Unit Amount'),
        width: '1fr',
        render: (item: CustomerInvoiceItem) => item.unit_amount,
    },
    {
        title: I18n.get('Total Amount'),
        width: '1fr',
        render: (item: CustomerInvoiceItem) => item.amount,
    },
    {
        title: I18n.get('Currency'),
        width: '1fr',
        render: (item: CustomerInvoiceItem) => item.currency,
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: CustomerInvoiceItem) => formatTimestamp(item.created as string),
    },
];
