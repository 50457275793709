import PropTypes from 'prop-types';
import {Field} from 'formik';

import {Grid} from '@mantine/core';
import {
    FormikNumberInput,
    FormikSelect
} from "@flexinet/ui-components";
import {CustomersAutocomplete} from "../../customers/inputs/CustomersAutocomplete.tsx";
import {PricesAutocomplete} from "../../prices/inputs/PricesAutocomplete.tsx";
import {InvoicesAutocomplete} from "../../invoices/inputs/InvoicesAutocomplete.tsx";

export const FormComponent = () => {

    return (
        <Grid>

            <Grid.Col span={12}>
                <Field
                    name='customer'
                    label='Customer'
                    description='Please select customer'
                    component={CustomersAutocomplete}
                    disabled={true}
                    withAsterisk
                />
            </Grid.Col>


            <Grid.Col span={12}>
                <Field
                    name='price'
                    label='Price'
                    description='Please enter the price'
                    component={PricesAutocomplete}
                    withAsterisk
                />
            </Grid.Col>

            <Grid.Col span={12}>
                <Field
                    name='invoice'
                    label='Invoice'
                    description='Please enter the invoice'
                    component={InvoicesAutocomplete}
                    withAsterisk
                />
            </Grid.Col>

            <Grid.Col span={6}>
                <Field
                    name='quantity'
                    label='Quantity'
                    description='Please enter the quantity'
                    component={FormikNumberInput}
                    withAsterisk
                />
            </Grid.Col>

            <Grid.Col span={6}>
                <Field
                    name='currency'
                    label='Currency'
                    description='Please enter the account currency'
                    data={[{
                        label: 'USD',
                        value: 'usd'
                    }]}
                    component={FormikSelect}
                    withAsterisk
                />
            </Grid.Col>

        </Grid>
    );
};

FormComponent.propTypes = {
    values: PropTypes.object.isRequired,
};
