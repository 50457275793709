import {FieldProps} from "formik";

import {
    IbanElement,
    IbanElementProps
} from "@stripe/react-stripe-js";
import {
    Stack,
    Text,
    TextInputProps,
    Title
} from "@mantine/core";

// Custom styling can be passed as options when creating an Element.
const IBAN_STYLE = {
    base: {
        color: '#32325d',
        fontSize: '16px',
        '::placeholder': {
            color: '#aab7c4'
        },
        ':-webkit-autofill': {
            color: '#32325d',
        },
        borer: 'solid 1px'
    },
    invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
        ':-webkit-autofill': {
            color: '#fa755a',
        },
    }
};

const IBAN_ELEMENT_OPTIONS = {
    supportedCountries: ['SEPA'],
    // Elements can use a placeholder as an example IBAN that reflects
    // the IBAN format of your customer's country. If you know your
    // customer's country, we recommend that you pass it to the Element as the
    // placeholderCountry.
    placeholderCountry: 'BG',
    style: IBAN_STYLE,
};

export const FormikStripeIban = ({
                                     field,
                                     form: {setFieldValue},
                                     ...rest
                                 }: FieldProps & IbanElementProps & TextInputProps) => {

    return <Stack>
        <Title order={6}><Text>{rest.label}:</Text></Title>
        <IbanElement {...field} {...rest} options={IBAN_ELEMENT_OPTIONS}
                     onChange={value => setFieldValue(field.name, value)}/>
    </Stack>;
};

