export const listSubscriptions = `
   query listSubscriptions {
       listSubscriptions {
           items {
              id
              customer
              description
              currency
              status
           }
       }
   }
`;

export const getSubscription = `
   query getSubscription($id:ID!) {
       getSubscription(id:$id) {
            id
            customer
            items {
                id
              }
            description
            currency
            status
       } 
}`;
