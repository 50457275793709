import React from 'react'
import {
    NavLink,
    useParams
} from 'react-router-dom';

import {
    Box,
    Breadcrumbs,
} from '@mantine/core';
import {BreadcrumbElement} from "../../../types/menu-items.ts";
import {Card} from "../types.ts";
import {Customer} from "../../customers/types.ts";
import {useCustomer} from "../../customers/hooks/useCustomer.ts";


const renderBreadcrumbs = (elements: BreadcrumbElement[]) => {

    const breadcrumbs = elements.map((item, index) => (
        <React.Fragment key={index}>{item.href ?
            <NavLink to={item.href}>{item.title}</NavLink> : item.title}</React.Fragment>
    ));
    return (
        <Box p={'xs'}><Breadcrumbs separator='→' mt='xs'>{breadcrumbs}</Breadcrumbs></Box>
    );

}

const rootPathElements = (customer: Customer): BreadcrumbElement[] => {

    return [
        {
            title: 'Home',
            href: '/dashboard'
        },
        {
            title: 'Customers',
            href: `/customers`
        },
        {
            title: customer.name as string,
            href: `/customers/${customer.id}`
        }
    ];

}


export const AddPageNavigation = () => {
    const {id: customerId} = useParams();

    if (typeof customerId === 'undefined') {
        throw new Error('customerId is required parameter')
    }

    const {record: customer} = useCustomer(customerId)

    const elements: BreadcrumbElement[] = rootPathElements(customer)
        .concat([
                    {title: 'Cards'},
                    {title: 'Add Card'}
                ]);
    return renderBreadcrumbs(elements)
};

export const EditPageNavigation = ({record}: { record: Card }) => {

    const {record: customer} = useCustomer(record.customer)

    const elements = rootPathElements(customer)
        .concat([
                    {title: 'Cards'},
                    {title: record.id as string}
                ]);
    return renderBreadcrumbs(elements)
};

