import {stage} from '../stage';


const stripeAPI = {
    prod: {
        endpoint: 'https://acyaqdzi3vbv5nyk7dc6qtmude.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
        apiKey: '',
    },
    test: {
        endpoint: 'https://hoyf5aarv5hszmfxzxy643fugm.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
        apiKey: '',
    },
    dev: {
        endpoint: 'https://p52e2ywrqbdmjb33o6o6g2fo6q.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
        apiKey: '',
    },
    ide: {
        endpoint: 'https://p52e2ywrqbdmjb33o6o6g2fo6q.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
        apiKey: '',
    },
};

export const appSyncConfig = stripeAPI[stage];

