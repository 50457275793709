export const listSubscriptionItems = `
   query listSubscriptionItems($customer: ID! ) {
       listSubscriptionItems(customer: $customer) {
           items {
              id
              customer
              country
              currency
              account_holder_name
              account_holder_type
              bank_name
              routing_number
           }
       }
   }
`;

export const getSubscriptionItem = `
   query getSubscriptionItem($customer: ID!, $id:ID!) {
       getSubscriptionItem(customer:$customer, id:$id) {
          id
          customer
          country
          currency
          account_holder_name
          account_holder_type
          bank_name
          routing_number
       } 
}`;
