import {
    getInvoiceItem,
    listInvoiceItems
} from './queries';
import {
    createInvoiceItem,
    deleteInvoiceItem,
    updateInvoiceItem,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {CustomerInvoiceItem} from "../types.ts";
import {V6Client} from "@aws-amplify/api-graphql";
import {generateClient} from "aws-amplify/api";
import {
    notifyError,
    notifySuccess,
    showErrorNotification
} from "../../../components/ServiceNotifications.tsx";


export type ListInvoiceItems = {
    listInvoiceItems: { items: CustomerInvoiceItem[] }
}

export type GetInvoiceItem = {
    getInvoiceItem: CustomerInvoiceItem
}

export type CreateInvoiceItem = {
    createInvoiceItem: CustomerInvoiceItem
}

export type UpdateInvoiceItem = {
    updateInvoiceItem: CustomerInvoiceItem
}

export type DeleteInvoiceItem = {
    deleteInvoiceItem: string
}

let client: V6Client
try {
    client = generateClient();
} catch (e: any) {
    showErrorNotification(e)
}

export const fetchRecords = async (filter: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<ListInvoiceItems>>({
                                                                                  query: listInvoiceItems,
                                                                                  variables: {filter: filter},
                                                                              });
        return response.data?.listInvoiceItems.items;
    } catch (e) {
        notifyError(e)
    }
};

export const fetch = async (customerId: string, id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetInvoiceItem>>({
                                                                                query: getInvoiceItem,
                                                                                variables: {
                                                                                    customer: customerId,
                                                                                    id: id
                                                                                },
                                                                            });
        return response.data?.getInvoiceItem;
    } catch (e) {
        notifyError(e)
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreateInvoiceItem>>({
                                                                                   query: createInvoiceItem,
                                                                                   variables: {input: payload},
                                                                               });
        console.debug('in the service response', response);
        notifySuccess('Invoice item created')
        return response.data?.createInvoiceItem;
    } catch (e) {
        notifyError(e)
    }
};

export const update = async (record: CustomerInvoiceItem) => {

    delete record.created;
    delete record.updated;

    try {
        const response = await client.graphql<GraphQLQuery<UpdateInvoiceItem>>({
                                                                                   query: (updateInvoiceItem),
                                                                                   variables: {input: record},
                                                                               });
        notifySuccess('Invoice item updated')
        return response.data?.updateInvoiceItem;
    } catch (e) {
        notifyError(e)
    }
};

export const deleteRecord = async (customerId: string, id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteInvoiceItem>>({
                                                                                   query: (deleteInvoiceItem),
                                                                                   variables: {
                                                                                       input: {
                                                                                           customer: customerId,
                                                                                           id: id
                                                                                       }
                                                                                   },

                                                                               });
        notifySuccess('Invoice item deleted')
        return response.data?.deleteInvoiceItem;
    } catch (e) {
        notifyError(e)
    }
};
