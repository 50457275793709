import {
    getCard,
    listCards
} from './queries';
import {
    createCard,
    deleteCard,
    updateCard,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {Card} from "../types.ts";
import {V6Client} from "@aws-amplify/api-graphql";
import {generateClient} from "aws-amplify/api";
import {
    notifyError,
    notifySuccess,
    showErrorNotification
} from "../../../components/ServiceNotifications.tsx";


export type ListCards = {
    listCards: { items: Card[] }
}

export type GetCard = {
    getCard: Card
}

export type CreateCard = {
    createCard: Card
}

export type UpdateCard = {
    updateCard: Card
}

export type DeleteCard = {
    deleteCard: string
}


let client: V6Client
try {
    client = generateClient();
} catch (e: any) {
    showErrorNotification(e)
}


export const fetchRecords = async (customerId: string) => {

    try {
        const response = await client.graphql<GraphQLQuery<ListCards>>({
                                                                           query: listCards,
                                                                           variables: {
                                                                               customer: customerId
                                                                           },
                                                                       });

        return response.data?.listCards.items;
    } catch (e) {
        notifyError(e)
    }
};

export const fetch = async (customerId: string, id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetCard>>({
                                                                         query: getCard,
                                                                         variables: {
                                                                             customer: customerId,
                                                                             id: id
                                                                         },
                                                                     });
        return response.data?.getCard;
    } catch (e) {
        notifyError(e)
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreateCard>>({
                                                                            query: createCard,
                                                                            variables: {input: payload},
                                                                        });
        console.debug('in the service response', response);
        notifySuccess('Card created');
        return response.data?.createCard;
    } catch (e) {
        notifyError(e)
    }
};

export const update = async (record: Card) => {

    delete record.created;
    delete record.updated;

    try {
        const response = await client.graphql<GraphQLQuery<UpdateCard>>({
                                                                            query: (updateCard),
                                                                            variables: {input: record},
                                                                        });
        notifySuccess('Card updated')
        return response.data?.updateCard;
    } catch (e) {
        notifyError(e)
    }
};

export const deleteRecord = async (customerId: string, id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteCard>>({
                                                                            query: (deleteCard),
                                                                            variables: {
                                                                                input: {
                                                                                    customer: customerId,
                                                                                    id: id
                                                                                }
                                                                            },

                                                                        });
        notifySuccess('Card deleted')
        return response.data?.deleteCard;
    } catch (e) {
        notifyError(e)
    }
};
