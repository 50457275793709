import PropTypes from 'prop-types';
import {Field} from 'formik';

import {Grid} from '@mantine/core';
import {
    FormikRadioGroup,
    FormikSelect,
    FormikTextInput
} from "@flexinet/ui-components";
import {FormikStripeIban} from "../../../components/FormikStripeIban.tsx";

export const FormComponent = () => {

    return (
        <Grid>

            <Grid.Col span={12}>
                <Field
                    name='iban'
                    label='IBAN'
                    description='Please enter the routing number if available'
                    component={FormikStripeIban}
                />
            </Grid.Col>

            <Grid.Col span={12}>
                <Field
                    name='routing_number'
                    label='Routing Number'
                    description='Please enter the routing number if available'
                    component={FormikTextInput}
                />
            </Grid.Col>

            <Grid.Col span={12}>
                <Field
                    name='account_number'
                    label='Account Number'
                    description='Please enter the account number'
                    component={FormikTextInput}
                    withAsterisk
                />
            </Grid.Col>

            <Grid.Col span={12}>
                <Field
                    name='account_holder_name'
                    label='Account Holder Name'
                    description='Please enter the account holder name'
                    component={FormikTextInput}
                    withAsterisk
                />
            </Grid.Col>

            <Grid.Col span={12}>
                <Field
                    name='account_holder_type'
                    label='Account Holder Type'
                    description='Please enter the account holder type'
                    options={[{
                        label: 'Individual',
                        value: 'individual'
                    }, {
                        label: 'Company',
                        value: 'company'
                    }]}
                    component={FormikRadioGroup}
                    withAsterisk
                />
            </Grid.Col>

            <Grid.Col span={12}>
                <Field
                    name='country'
                    label='Country'
                    description='Please enter the account country'
                    data={[{
                        label: 'US',
                        value: 'US'
                    }]}
                    component={FormikSelect}
                    withAsterisk
                />
            </Grid.Col>

            <Grid.Col span={12}>
                <Field
                    name='currency'
                    label='Currency'
                    description='Please enter the account currency'
                    data={[{
                        label: 'USD',
                        value: 'usd'
                    }]}
                    component={FormikSelect}
                    withAsterisk
                />
            </Grid.Col>


        </Grid>
    );
};

FormComponent.propTypes = {
    values: PropTypes.object.isRequired,
};
