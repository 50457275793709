import PropTypes from 'prop-types';
import {Field} from 'formik';

import {SimpleGrid} from '@mantine/core';

import {FormikTextInput} from '@flexinet/ui-components';


export const FormComponent = () => {

    return (
        <SimpleGrid cols={1}>

            <Field
                name='name'
                label='Name'
                description='Please enter product name'
                component={FormikTextInput}
                withAsterisk
            />

        </SimpleGrid>
    );
};

FormComponent.propTypes = {
    values: PropTypes.object.isRequired,
};
