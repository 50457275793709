import * as Yup from 'yup';

export const RecordSchema = Yup.object()
                               .shape({
                                          customer: Yup.string()
                                                       .required('Required'),
                                          price: Yup.string()
                                                    .required('Required'),
                                          amount: Yup.number(),
                                          invoice: Yup.string(),
                                          quantity: Yup.number(),
                                          unit_amount: Yup.number(),
                                          currency: Yup.string()
                                      });
