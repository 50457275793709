import {useEffect} from 'react';

import {useParams} from 'react-router-dom';

import {Stack} from '@mantine/core';

import {RecordForm} from '../forms/RecordForm';

import {
    FormPageLayout,
    PlatformLoader,
} from '@flexinet/ui-components';
import {Profile} from '../components/Profile';

import {EditPageNavigation} from '../components/EditPageNavigation';
import {useCustomer} from '../hooks/useCustomer.ts';
import {useCustomersSelection} from '../hooks/useCustomersSelection.ts';
import {useEditPageTools} from '../hooks/useEditPageTools';

import {IndexPage as BankAccountsPage} from '../../bank-accounts/pages/IndexPage.tsx'
import {IndexPage as CardsPage} from '../../cards/pages/IndexPage.tsx'
import {IndexPage as InvoiceItemsPage} from '../../customer-invoice-items/pages/IndexPage.tsx'
import {useCustomers} from "../hooks/useCustomers.ts";


export const EditPage = () => {

    const {id: customerId} = useParams();

    if (typeof customerId === 'undefined') {
        throw new Error('customerId is required parameter');
    }

    const {fetchRecords} = useCustomers();

    const {
        record: customer,
        fetchRecord,
        updateRecord,
    } = useCustomer(customerId);

    const {handleClearSelection} = useCustomersSelection();

    const {
        mode,
        tools,
    } = useEditPageTools();


    useEffect(() => {
        handleClearSelection();
    }, []);

    useEffect(() => {
        fetchRecords();
    }, []);

    useEffect(() => {
        fetchRecord();
    }, [customerId]);

    if (!customer) {
        return <PlatformLoader message='Loading customer...Please wait'/>;
    }


    return (
        <FormPageLayout
            title={customer.name ? customer.name : customer.id}
            pageNavigation={<EditPageNavigation component={customer}/>}
            tools={tools}
        >


            {mode === 'view' ?
                <Stack gap='lg'>
                    <Profile
                        record={customer}
                    />

                    <CardsPage/>
                    <BankAccountsPage/>
                    <InvoiceItemsPage/>

                </Stack>
                :
                <RecordForm
                    record={customer}
                    handleSubmit={updateRecord}
                />}


        </FormPageLayout>
    );
};
