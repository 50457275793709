import PropTypes from 'prop-types';
import {Field} from 'formik';

import {Grid} from '@mantine/core';
import {
    FormikSelect,
    FormikSwitch,
    FormikTextInput
} from "@flexinet/ui-components";
import {Price} from "../types.ts";
import {ProductsAutocomplete} from "../../products/inputs/ProductsAutocomplete.tsx";


export const FormComponent = ({values}: { values: Price }) => {

    return (
        <Grid>
            <Grid.Col span={6}>
                <Field
                    name='product'
                    label='Product'
                    description='Please enter the product'
                    component={ProductsAutocomplete}
                    withAsterisk
                />
            </Grid.Col>

            <Grid.Col span={6}>
                <Field
                    name='unit_amount'
                    label='Unit Amount'
                    description='Please enter the unit amount'
                    component={FormikTextInput}
                    withAsterisk
                />
            </Grid.Col>
            <Grid.Col span={6}>
                <Field
                    name='currency'
                    label='Currency'
                    description='Please enter the currency'
                    data={[{
                        label: 'USD',
                        value: 'usd'
                    }]}
                    component={FormikSelect}
                    withAsterisk
                />
            </Grid.Col>

            <Grid.Col span={6}>
                <Field
                    name='billing_scheme'
                    label='Billing Scheme'
                    description='Please enter the billing scheme'
                    data={[{
                        label: 'Per Unit',
                        value: 'per_unit'
                    }, {
                        label: 'Tiered',
                        value: 'tiered'
                    }]}
                    component={FormikSelect}
                    withAsterisk
                />
            </Grid.Col>

            <Grid.Col span={6}>
                <Field
                    name='active'
                    label='Active'
                    description='Please enter if the price can be used for new products'
                    component={FormikSwitch}
                    withAsterisk
                />
            </Grid.Col>

            <Grid.Col span={12}>
                <Field
                    name='recurring'
                    label='Recurring'
                    description='Please enter if the price is recurring'
                    component={FormikSwitch}
                    withAsterisk
                />
            </Grid.Col>

            {values.recurring ? (

                <>
                    <Grid.Col span={6}>
                        <Field
                            name='recurring.interval'
                            label='Interval'
                            description='Please enter the interval'
                            data={[{
                                label: 'Year',
                                value: 'year'
                            }, {
                                label: 'Month',
                                value: 'month'
                            }, {
                                label: 'Week',
                                value: 'week'
                            }, {
                                label: 'Day',
                                value: 'day'
                            }]}
                            component={FormikSelect}
                        />
                    </Grid.Col>

                    <Grid.Col span={6}>
                        <Field
                            name='recurring.interval_count'
                            label='Interval Count'
                            description='Please enter the interval'
                            data={[{
                                label: '1',
                                value: 1
                            }, {
                                label: '2',
                                value: 2
                            }, {
                                label: '3',
                                value: 3
                            }]}
                            component={FormikSelect}
                        />
                    </Grid.Col>

                </>

            ) : null}

        </Grid>
    );
};


FormComponent.propTypes = {
    values: PropTypes.object.isRequired,
};
