import {
    deleteInvoiceItem,
    fetchInvoiceItem,
    saveInvoiceItem,
    updateInvoiceItem,
} from '../reducers/recordsSlice';
import {recordSelector} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';
import {CustomerInvoiceItem} from '../types';

export const useCustomerInvoiceItem = (customerId: string, customerInvoiceItemId?: string) => {

    if (typeof customerId === 'undefined') {
        throw new Error('customerId is required parameter')
    }

    const dispatch = useAppDispatch();

    const {
        record,
        loading,
    } = useAppSelector((state) =>
                           recordSelector(state, customerInvoiceItemId ? customerInvoiceItemId : ''),
    );

    // FETCH
    const fetchRecord = () => {
        if (typeof customerInvoiceItemId === 'undefined') {
            throw new Error('customerInvoiceItemId is required parameter');
        }
        dispatch(fetchInvoiceItem({
                                      customerId: customerId,
                                      id: customerInvoiceItemId
                                  }));
    };

    // CREATE, UPDATE DELETE
    const createRecord = (values: CustomerInvoiceItem) => {
        dispatch(saveInvoiceItem({
                                     customerId: customerId,
                                     payload: values
                                 }));
    };

    const updateRecord = (values: CustomerInvoiceItem) => {
        if (typeof customerInvoiceItemId === 'undefined') {
            throw new Error('customerInvoiceItemId is required parameter');
        }

        dispatch(updateInvoiceItem({
                                       customerId: customerId,
                                       id: customerInvoiceItemId,
                                       record: values,
                                   }));
    };

    const deleteRecord = () => {
        if (typeof customerInvoiceItemId === 'undefined') {
            throw new Error('customerInvoiceItemId is required parameter');
        }
        dispatch(deleteInvoiceItem({
                                       customerId: customerId,
                                       id: customerInvoiceItemId
                                   }));
    };

    return {
        record,
        loading,
        fetchRecord,
        createRecord,
        updateRecord,
        deleteRecord,
    };
};
