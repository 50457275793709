import {FieldProps} from "formik";

import {
    CardElement,
    CardElementProps
} from "@stripe/react-stripe-js";
import {
    Stack,
    Text,
    TextInputProps,
    Title
} from "@mantine/core";


export const FormikStripeCard = ({
                                     field,
                                     form: {setFieldValue},
                                     ...rest
                                 }: FieldProps & CardElementProps & TextInputProps) => {

    return <Stack>
        <Title order={6}><Text>{rest.label}:</Text></Title>
        <CardElement {...field} {...rest} onChange={value => setFieldValue(field.name, value)}/>
    </Stack>;
};

