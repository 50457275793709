export const createInvoiceItem = `
    mutation createInvoiceItem($input: CreateInvoiceItemInput!) {
        createInvoiceItem(input:$input) {
            id
            customer
            amount
            currency
            description
            period {
                start
                end
            }
            price
            invoice
            quantity
            subscription
            unit_amount
        }
}`;

export const updateInvoiceItem = `
    mutation updateInvoiceItem($input: UpdateInvoiceItemInput!) {
        updateInvoiceItem(input:$input){
            id
            customer
            amount
            currency
            description
            period {
                start
                end
            }
            price
            invoice
            quantity
            subscription
            unit_amount
        }
}`;

export const deleteInvoiceItem = `
    mutation deleteInvoiceItem($input: DeleteInvoiceItemInput!) {
        deleteInvoiceItem(input:$input) 
}`;
