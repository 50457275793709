import {Box} from "@mantine/core";
import classes from "./MarketingComponent.module.css";

export const MarketingComponent = () => {

    return (
        <Box className={classes.heroImage}/>
    )

}
