export const listBankAccounts = `
   query listBankAccounts($customer: ID! ) {
       listBankAccounts(customer: $customer) {
           items {
              id
              customer
              country
              currency
              account_holder_name
              account_holder_type
              bank_name
              routing_number
           }
       }
   }
`;

export const getBankAccount = `
   query getBankAccount($customer: ID!, $id:ID!) {
       getBankAccount(customer:$customer, id:$id) {
          id
          customer
          country
          currency
          account_holder_name
          account_holder_type
          bank_name
          routing_number
       } 
}`;
