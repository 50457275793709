import {stage} from './stage';

const platform = {
    prod: {
        portal: 'https://portal.flexi.network',
        console: 'https://console.flexi.network',
        stripe: 'https://stripe.flexi.network',
    },
    test: {
        portal: 'https://portal.test.flexi.network',
        console: 'https://console.test.flexi.network',
        stripe: 'https://stripe.test.flexi.network',
    },
    dev: {
        portal: 'https://portal.dev.flexi.network',
        console: 'https://console.dev.flexi.network',
        stripe: 'https://stripe.dev.flexi.network',
    },
    ide: {
        portal: 'https://localhost:8011',
        console: 'https://localhost:8012',
        stripe: 'https://localhost:8013',
    },
};

export default platform[stage];
