export const listPrices = `
   query listPrices {
       listPrices {
           items {
              id
              currency
              product
              unit_amount
              active
              nickname
              billing_scheme
              recurring {
                interval
                interval_count
              }
           }
       }
   }
`;

export const getPrice = `
   query getPrice($id:ID!) {
       getPrice(id:$id) {
         id
         currency
         product
         unit_amount
         active
         nickname
         billing_scheme
         recurring {
            interval
            interval_count
         }
       } 
}`;
