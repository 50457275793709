import {stage} from './stage';

const stripe = {
    prod: {
        publishableKey: 'pk_test_51PCp2pHFN7HgbQ5GfapDnzS4pYfysrTjegKneBwkpvmzdeN9K5wTmTVRPCPdfqV3Jsb3ZETrm1daP4zGasMTgR370006YIUiXr',
    },
    test: {
        publishableKey: 'pk_test_51PCp2pHFN7HgbQ5GfapDnzS4pYfysrTjegKneBwkpvmzdeN9K5wTmTVRPCPdfqV3Jsb3ZETrm1daP4zGasMTgR370006YIUiXr',
    },
    dev: {
        publishableKey: 'pk_test_51PCp2pHFN7HgbQ5GfapDnzS4pYfysrTjegKneBwkpvmzdeN9K5wTmTVRPCPdfqV3Jsb3ZETrm1daP4zGasMTgR370006YIUiXr',
    },
    ide: {
        publishableKey: 'pk_test_51PCp2pHFN7HgbQ5GfapDnzS4pYfysrTjegKneBwkpvmzdeN9K5wTmTVRPCPdfqV3Jsb3ZETrm1daP4zGasMTgR370006YIUiXr',
    },
};

export default {
    publishableKey: stripe[stage].publishableKey,
};
