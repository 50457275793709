import {PlatformNavbarMenuItem} from "@flexinet/ui-components";
import {v4 as uuidv4} from 'uuid';
import {
    AiOutlineAppstore,
    AiOutlineClockCircle,
    AiOutlineDollarCircle,
    AiOutlineFileDone,
    AiOutlineHome
} from 'react-icons/ai'
import {IconContext} from "react-icons";
import {MdOutlineBusinessCenter} from "react-icons/md";

export const getMenuItems = () => {

    return [
        {
            id: uuidv4(),
            link: '/dashboard',
            label: 'Dashboard',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineHome/></IconContext.Provider>,
            rbac: 'ViewDashboard',
        }, {
            id: uuidv4(),
            link: '/subscriptions',
            label: 'Subscriptions',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineClockCircle/></IconContext.Provider>,
            rbac: 'ListSubscriptions',
            children: [],
        }, {
            id: uuidv4(),
            link: '/invoices',
            label: 'Invoices',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineFileDone/></IconContext.Provider>,
            rbac: 'ListInvoices',
            children: [],
        }, {
            id: uuidv4(),
            link: '/customers',
            label: 'Customers',
            icon: <IconContext.Provider value={{size: '2em'}}><MdOutlineBusinessCenter/></IconContext.Provider>,
            rbac: 'ListCustomers',
            children: [],
        }, {
            id: uuidv4(),
            link: '/products',
            label: 'Products',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineAppstore/></IconContext.Provider>,
            rbac: 'ListProducts',
            children: [],
        }, {
            id: uuidv4(),
            link: '/prices',
            label: 'Prices',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineDollarCircle/></IconContext.Provider>,
            rbac: 'ListPrices',
            children: [],
        }] as PlatformNavbarMenuItem[];

};

