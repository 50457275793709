export const createProduct = `
    mutation createProduct($input: CreateProductInput!) {
        createProduct(input:$input) {
            id
            name
    }
}`;

export const updateProduct = `
    mutation updateProduct($input: UpdateProductInput!) {
        updateProduct(input:$input){
            id
            name
        }
}`;

export const deleteProduct = `
    mutation deleteProduct($input: DeleteProductInput!) {
        deleteProduct(input:$input) 
}`;
